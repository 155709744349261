import React from "react";

const Page404 = () => {
  return (
    <section className="bg-white pt-20">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <h1 className="text-primary-600 mb-4 text-7xl font-extrabold tracking-tight lg:text-9xl">
          404 - Sayfa bulunamadı
        </h1>
      </div>
    </section>
  );
};

export default Page404;
