import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconClock, IconX } from "@tabler/icons-react";
import { timeAgo } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

const sourceTitle = Object.freeze({
  Yargitay: "Yargıtay",
  Danistay: "Danıştay",
  AYM: "Anayasa Mahkemesi",
  CEDH: "Avrupa İnsan Hakları Mahkemesi",
  UYAP: "UYAP"
});

const Bookmarks = () => {
  const navigate = useNavigate();
  const [bookmarkList, setBookmarkList] = useState([]);

  useEffect(() => {
    const init = async () => {
      await api.chat.getFavorites().then(res => {
        setBookmarkList(res.data?.favorites);
      });
    };

    init();
  }, []);

  const MevzuatContent = ({ item }) => {
    return (
      <div>
        <div className="text-sm font-medium text-gray-800 dark:text-gray-200">
          {item.mevzuat_adi}
        </div>
        <div className="mt-2 flex gap-3 text-xs text-gray-600 md:flex-col md:gap-1 dark:text-gray-500">
          <div className={"flex gap-1"}>
            <span>Tür:</span>
            <strong className="font-medium">{item.mevzuat_turu}</strong>
          </div>
          <div className="flex gap-1">
            <span>Mevzuat Numarası:</span>
            <strong className="font-medium">{item.mevzuat_no}</strong>
          </div>
          <div className="flex gap-1">
            <span>Kabul Tarihi:</span>
            <strong className="font-medium">{item.kabul_tarihi}</strong>
          </div>
          <div className="flex gap-1">
            <span>Resmi Gazete Sayısı:</span>
            <strong className="font-medium">{item.resmi_gazete_sayisi}</strong>
          </div>
          <div className="flex gap-1">
            <span>Resmi Gazete Tarihi:</span>
            <strong className="font-medium">{item.resmi_gazete_tarihi}</strong>
          </div>
        </div>
      </div>
    );
  };

  const IctihatContent = ({ item }) => {
    return (
      <div>
        <div className="text-sm font-medium text-gray-800 dark:text-gray-200">
          {sourceTitle[item.document_source]} {item.daire} Kararı
        </div>
        <div className="mt-2 flex gap-3 text-xs text-gray-600 md:flex-col md:gap-1 dark:text-gray-500">
          <div className={"flex gap-1"}>
            <span>Daire:</span>
            <strong className="font-medium">{item.daire}</strong>
          </div>
          <div className="flex gap-1">
            <span>Esas No:</span>
            <strong className="font-medium">{item.esas_no}</strong>
          </div>
          <div className="flex gap-1">
            <span>Karar No:</span>
            <strong className="font-medium">{item.karar_no}</strong>
          </div>
          <div className="flex gap-1">
            <span>Karar Tarihi:</span>
            <strong className="font-medium">{item.karar_tarihi}</strong>
          </div>
        </div>
      </div>
    );
  };

  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4  pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        Kaydedilenler
      </h1>
      <div className="mx-auto w-full max-w-4xl">
        <section className={"flex min-w-full flex-col"}>
          {bookmarkList.map((item, i) => (
            <div
              onClick={() =>
                navigate(
                  `/doc/${item.document_type}?s3Url=${item.s3_url_html || item.s3_url_json}`
                )
              }
              className={`cursor-pointer py-3 ${i === 0 ? "" : "border-t border-gray-100 dark:border-gray-800"}`}
              key={item.thread_id}
            >
              {item.document_type === "mevzuat" ? (
                <MevzuatContent item={item} />
              ) : (
                <IctihatContent item={item} />
              )}
              <div>
                <span className="mt-2 flex items-center gap-1 text-xs text-gray-500">
                  <IconClock size={13} />
                  <span>{timeAgo(item.created_at)}</span>
                </span>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Bookmarks;
