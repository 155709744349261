import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col py-10">
      <h1 className="mb-4 font-display text-2xl font-medium text-gray-950">
        Lexgine Gizlilik Politikası
      </h1>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Son güncelleme tarihi: 1 Ekim 2024
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        1. Giriş
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine ("biz", "bize", "bizim") olarak, gizliliğinize önem veriyoruz.
        Bu Gizlilik Politikası, hizmetlerimizi kullanırken kişisel verilerinizin
        nasıl toplandığını, kullanıldığını ve paylaşıldığını açıklar.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        2. Politikanın Kabulü
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine'i kullanarak, bu Gizlilik Politikası'nı okuduğunuzu,
        anladığınızı ve kabul ettiğinizi beyan edersiniz. Sitemize erişiminiz ve
        kullanımınız, bu politikada belirtilen tüm veri toplama ve kullanım
        uygulamalarına açık rızanız olarak kabul edilir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        3. Toplanan Bilgiler
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.1. Kişisel Bilgiler: Adınız, e-posta adresiniz, telefon numaranız, IP
        adresiniz ve konum bilgileriniz dahil olmak üzere her türlü kişisel
        bilgiyi toplayabiliriz.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.2. Kullanım Verileri: Hizmetimizle nasıl etkileşimde bulunduğunuza
        dair bilgileri toplayabiliriz.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.3. Hukuki Sorular ve Araştırmalar: Platformumuzda yaptığınız tüm
        hukuki araştırmaları ve sorduğunuz soruları kaydedebiliriz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        4. Bilgilerin Kullanımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Topladığımız bilgileri aşağıdaki amaçlar için kullanabiliriz:
      </p>
      <ul className="mb-4 list-disc pl-5 text-base leading-7 text-gray-700">
        <li>Hizmetlerimizi sağlamak ve geliştirmek</li>
        <li>Kullanıcı deneyimini kişiselleştirmek</li>
        <li>Müşteri hizmetleri sağlamak</li>
        <li>Pazarlama ve reklam amaçlı iletişim göndermek</li>
        <li>Yasal yükümlülüklerimizi yerine getirmek</li>
      </ul>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        5. Bilgi Paylaşımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Kişisel bilgilerinizi aşağıdaki durumlarda üçüncü taraflarla
        paylaşabiliriz:
      </p>
      <ul className="mb-4 list-disc pl-5 text-base leading-7 text-gray-700">
        <li>İş ortaklarımız ve hizmet sağlayıcılarımızla</li>
        <li>Yasal zorunluluk durumunda yetkili makamlarla</li>
        <li>Şirket birleşmesi, satışı veya yeniden yapılandırması durumunda</li>
      </ul>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        6. Veri Güvenliği
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Verilerinizi korumak için uygun teknik ve organizasyonel önlemler
        alıyoruz. Ancak, internet üzerinden yapılan hiçbir veri iletiminin veya
        elektronik depolamanın %100 güvenli olmadığını kabul edersiniz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        7. Kullanıcı Hakları
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu politikayı kabul ederek, kişisel verilerinizin işlenmesine açık rıza
        vermiş olursunuz. Verilerinize erişim, düzeltme veya silme talepleri
        için bizimle iletişime geçebilirsiniz, ancak bu tür talepleri yerine
        getirme yükümlülüğümüz bulunmamaktadır.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        8. Çocukların Gizliliği
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Hizmetlerimiz 18 yaş altı bireylere yönelik değildir. 18 yaş altındaki
        bireylerden bilerek kişisel bilgi toplamayız.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        9. Politika Değişiklikleri
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu Gizlilik Politikası'nı herhangi bir zamanda değiştirme hakkını saklı
        tutarız. Değişiklikler, bu sayfada yayınlandığı anda yürürlüğe girer.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        10. Sorumluluk Reddi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine, bu politikada belirtilen uygulamaların neden olabileceği
        herhangi bir zarar veya kayıptan sorumlu tutulamaz. Hizmetlerimizi
        kullanarak, tüm riskleri kabul etmiş olursunuz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        11. İletişim
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu Gizlilik Politikası hakkında sorularınız varsa, [iletişim bilgisi]
        adresinden bize ulaşabilirsiniz.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
