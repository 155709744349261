import React, { useState } from "react";
import { FormErrorMessage } from "components";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

const AppInput = ({
  label,
  name,
  placeholder,
  register,
  error,
  rules,
  type = "text",
  className = "",
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  return (
    <div className={`relative w-full ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`mb-1.5 block w-full text-sm font-medium ${
            error ? "text-red-600" : "text-gray-900"
          }`}
        >
          {label}
        </label>
      )}
      <input
        type={inputType}
        id={name}
        autoComplete="off"
        className={`block w-full rounded-3xl border bg-white px-4 py-3 text-sm text-gray-700 focus:border-primary-500 focus:ring-1 focus:ring-primary-500 dark:bg-gray-900 dark:text-white  ${
          error
            ? "border-red-500 focus:border-red-500"
            : "border-gray-300 focus:border-gray-700 dark:border-gray-700"
        }`}
        placeholder={placeholder}
        {...register(name, rules)}
        {...props}
      />
      {type === "password" && (
        <div
          className="absolute right-4 top-[38px] cursor-pointer text-gray-400"
          onClick={() =>
            setInputType(prevState =>
              prevState === "password" ? "text" : "password"
            )
          }
        >
          {inputType === "password" ? (
            <IconEyeOff size={20} />
          ) : (
            <IconEye size={20} />
          )}
        </div>
      )}
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppInput;
