import React, { useState, useEffect } from "react";
import { ChatInput } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import api from "../../services/api";

const DEFAULT_THREADS = [
  {
    title:
      "Hâkim şirketin, bağlı şirket alacaklılarına karşı sorumluluğu ve şartları nelerdir ve bu konuda içtihatlar nasıl bir yönelim göstermektedir?"
  },
  {
    title:
      "Örtülü kar dağıtımı yasağının, transfer fiyatlandırması ile ilişkisi nedir ve bu konuda vergi hukuku ile şirketler hukuku arasındaki etkileşim nasıl yorumlanır?"
  },
  {
    title:
      "Evliliğin bir yılı dolmadan, terk sebebiyle (TMK 164) boşanma davası açılabilir mi?"
  },
  {
    title:
      "Türk hukukunda 'culpa in contrahendo' ilkesinin uygulanması nasıldır ve hangi Yargıtay kararlarında yer alır?"
  }
];

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDefaultThread, setSelectedDefaultThread] = useState(null);
  const { isMobile } = useScreenDetector();
  const [threads, setThreads] = useState([]);

  const onSuccess = data => {
    navigate("/chat/new", { state: { data } });
  };

  useEffect(() => {
    const init = async () => {
      const { data } = await api.chat.randomQueries();
      setThreads(data.sample_queries);
    };
    init();
  }, []);

  const renderThreadItem = (item, index) => (
    <div key={`default-thread-${index}`} className="mb-2 md:mb-0">
      <div
        onClick={() => setSelectedDefaultThread(item)}
        className="font-small cursor-pointer rounded-3xl border border-gray-200 px-5 py-2 text-sm text-gray-800 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 hover:dark:bg-gray-800"
      >
        {item}
      </div>
    </div>
  );

  return (
    <div className="flex h-full w-full flex-col items-center justify-center md:h-auto md:min-h-full">
      <img src={"/lexgine-logo.svg"} width={48} height={48} className="mb-8" />
      <h1 className="mb-6 font-display text-2xl font-medium text-gray-900 dark:text-white">
        {t("_home.title")}
      </h1>
      <div className="mb-8 w-full max-w-2xl">
        {isMobile ? (
          <div className="flex flex-col gap-2">
            {Object.values(threads).slice(0, 2).map(renderThreadItem)}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            {Object.values(threads).map(renderThreadItem)}
          </div>
        )}
      </div>
      <ChatInput onSuccess={onSuccess} newThread={selectedDefaultThread} />
    </div>
  );
};

export default Home;
