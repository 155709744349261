import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { getQueryParamWithKey } from "../../utils/url";
import DOMPurify from "dompurify";
import { IconBookmark, IconBookmarkFilled } from "@tabler/icons-react";
import { convertToHTML, removeAllStylesFromText } from "../../utils/html";

const DocDetail = () => {
  const { type } = useParams();
  const s3Url = getQueryParamWithKey("s3Url");
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    documentId: null,
    documentSource: null
  });
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [summary, setSummary] = useState(null);

  const addBookmark = async () => {
    await api.chat
      .addFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(true);
      });
  };

  const removeBookmark = async () => {
    await api.chat
      .removeFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(false);
      });
  };

  useEffect(() => {
    const init = async () => {
      let documentId = "";
      let documentSource = "";
      if (type === "mevzuat") {
        const response = await api.content.getMevzuatContent(s3Url);
        if (response) {
          const getData = response.data;
          documentId = getData.id;
          documentSource = getData.source;
          const cleanHTML = DOMPurify.sanitize(getData.content);
          setContent(cleanHTML);
        }
      } else {
        const response = await api.content.getIctihatContent(s3Url);
        const { kararMetni, daire, kararTarihi, esasNo, kararNo, id, source } =
          response.data;
        documentId = id;
        documentSource = source;
        const htmlContent = `
          <html>
            <head>
              <title>İçtihat: ${daire} - ${kararTarihi}</title>
            </head>
            <body>
              <div class="meta prose prose-base prose-gray dark:prose-invert">
                <p><strong>Daire:</strong> ${daire}</p>
                <p><strong>Karar Tarihi:</strong> ${kararTarihi}</p>
                <p><strong>Esas No:</strong> ${esasNo}</p>
                <p><strong>Karar No:</strong> ${kararNo}</p>
              </div>
              <div class="prose prose-base prose-gray overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] mt-4">${convertToHTML(kararMetni)}</div>
            </body>
          </html>`;
        const cleanHTML = DOMPurify.sanitize(htmlContent);
        setContent(cleanHTML);
      }

      const response = await api.chat.getFavorites();
      const { favorites } = response.data;
      const isBookmarkedStatus = favorites.some(
        favorite =>
          favorite.document_id === documentId &&
          favorite.document_source === documentSource
      );
      setParams({ documentId, documentSource });
      setIsBookmarked(isBookmarkedStatus);

      const { data } =
        await api.doc[
          type === "mevzuat" ? "mevzuatSummarize" : "ictihatSummarize"
        ](s3Url);
      if (data.summary) {
        setSummary(data.summary);
      }

      setIsLoading(false);

      return () => {
        setContent(null);
        setIsLoading(true);
        setParams({ documentId: null, documentSource: null });
        setIsBookmarked(false);
      };
    };

    init();
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
        <h1 className={"font-display text-xl font-medium "}>
          {type === "mevzuat" ? "Mevzuat" : "İçtihat"} Özeti
        </h1>
        <div
          onClick={isBookmarked ? removeBookmark : addBookmark}
          className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:dark:bg-gray-800 "
        >
          {!isLoading &&
            (isBookmarked ? <IconBookmarkFilled /> : <IconBookmark />)}
        </div>
      </div>

      <div className="mx-auto w-full max-w-4xl">
        <section className={"flex min-w-full flex-col"}>
          {isLoading && (
            <div className="flex w-full animate-pulse">
              <div className="flex w-full flex-col">
                <div className="min-h-[640px] w-full rounded-lg bg-gray-100 shadow-md dark:bg-gray-700"></div>
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              {summary && (
                <article className="prose prose-base prose-gray mb-10 dark:prose-invert">
                  {summary}
                </article>
              )}
              <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
                <h1 className={"font-display text-xl font-medium "}>
                  {type === "mevzuat" ? "Mevzuat" : "İçtihat"} Detayı
                </h1>
              </div>
              {content && (
                <article
                  className="prose prose-base prose-gray overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] md:mt-6 md:px-4 md:py-8"
                  dangerouslySetInnerHTML={{
                    __html: removeAllStylesFromText(content)
                  }}
                />
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default DocDetail;
