import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen items-center justify-center bg-white md:h-auto">
      <div className="w-3/5 px-4 pb-16 pt-16 md:w-full">
        <img
          src="/lexgine-logo-vertical.svg"
          className={"mx-auto my-8 cursor-pointer"}
          width={164}
          height={164}
          alt="Lexgine"
          onClick={() => navigate("/auth/welcome")}
        />
        <Outlet />
        <div className="mt-20 flex items-center justify-center text-sm">
          <div
            className="cursor-pointer text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => navigate("/policies/terms")}
          >
            Kullanım Şartları
          </div>
          <span className="separator text-gray-400"></span>
          <div
            className="cursor-pointer text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => navigate("/policies/privacy-policy")}
          >
            Gizlilik Politikası
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
