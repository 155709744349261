export default {
  _auth: {
    welcomeTitle: "Yapay zeka destekli akıllı hukuk asistanınız",
    welcomeDescription:
      "Alpha test sürümüne hoş geldiniz. Lütfen giriş yapın ya da üye olun.",
    login: "Giriş yap",
    register: "Kayıt ol",
    forgotPassword: "Şifremi unuttum",
    resetPassword: "Şifremi sıfırla",
    logout: "Çıkış yap",
    name: "Ad",
    surname: "Soyad",
    email: "E-Posta",
    password: "Şifre",
    enterEmail: "E-Posta adresinizi giriniz",
    enterPassword: "Şifrenizi giriniz",
    passwordRepeat: "Şifre (Tekrar)",
    phone: "Cep telefonu"
  },
  _formErrorMessages: {
    required: "{{label}} alanı zorunludur.",
    minLength: "{{label}} alanını eksiksiz girmelisin.",
    pattern: "{{label}} alanına lütfen geçerli bir değer giriniz.",
    validate: "Şifreniz birbiriyle uyuşmuyor."
  },
  _sidebar: {
    newChat: "Yeni konu",
    darkTheme: "Koyu tema",
    lightTheme: "Açık tema"
  },
  _home: {
    title: "Hukuki araştırmanızı başlatın",
    subtitle: "Sorunuzu sorun veya örnek sorularımızdan birini seçin"
  },
  _chatInput: {
    askAnything: "Size nasıl yardımcı olabilirim?",
    ask: "Sor"
  },
  _history: {
    title: "Geçmiş"
  }
};
