import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "components";
import { emailPattern } from "constants/patterns";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    if (submitData.password === submitData.passwordRepeat) {
      await api.auth
        .register({
          email: submitData.email,
          password: submitData.password,
          phone_number: submitData.phone,
          given_name: submitData.name,
          family_name: submitData.surname
        })
        .then(() => {
          reset();
          setIsSuccess(true);
        })
        .catch(error => console.log(error));
    }
    setIsLoading(false);
  };

  return (
    <div className={"mx-auto mt-10 max-w-96"}>
      <h1 className="mb-8 text-center font-display text-3xl font-bold text-gray-800">
        Hemen üye olun{" "}
      </h1>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t("_auth.name")}
          name="name"
          error={errors.name}
          rules={{ required: true }}
          register={register}
          className="mb-4"
          placeholder={t("_auth.name")}
        />
        <Input
          label={t("_auth.surname")}
          name="surname"
          error={errors.surname}
          rules={{ required: true }}
          register={register}
          className="mb-4"
          placeholder={t("_auth.surname")}
        />
        <Input
          label={t("_auth.email")}
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          className="mb-4"
          placeholder={t("_auth.email")}
        />
        <Input
          label={t("_auth.password")}
          name="password"
          error={errors.password}
          rules={{
            required: true,
            minLength: 8
          }}
          type="password"
          className="mb-4"
          register={register}
          placeholder={t("_auth.password")}
        />
        <Input
          label={t("_auth.passwordRepeat")}
          name="passwordRepeat"
          error={errors.passwordRepeat}
          rules={{
            required: true,
            minLength: 8,
            validate: val => {
              if (watch("password") !== val) {
                return false;
              }
            }
          }}
          type="password"
          className="mb-8"
          register={register}
          placeholder={t("_auth.passwordRepeat")}
        />
        <Button type="submit" isLoading={isLoading}>
          {t("_auth.register")}
        </Button>
        {isSuccess && (
          <div className="mt-4 text-center text-sm font-medium text-green-700">
            Başarıyla üye oldunuz. Onay linkiniz mailinize iletilmiştir.
          </div>
        )}
        <div className="mt-8 text-center text-sm">
          Zaten üye misiniz?{" "}
          <span
            onClick={() => navigate("/auth/login")}
            className="cursor-pointer text-primary-600"
          >
            Giriş yapın.
          </span>
        </div>
      </form>
    </div>
  );
};

export default Register;
