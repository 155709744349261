import React, { useMemo } from "react";
import clsx from "clsx";

const PaginationComponent = ({
  totalPages,
  currentPage,
  pageSize,
  setCurrentPage
}) => {
  const starterPages = useMemo(() => {
    if (totalPages > 5) {
      const pageDifference = totalPages - currentPage;
      if (pageDifference > 3) {
        const pagesValues = currentPage === 1 ? [] : [currentPage - 1];
        return [
          ...pagesValues,
          currentPage,
          currentPage + 1,
          currentPage + 2,
          "...",
          totalPages
        ];
      } else if (pageDifference === 0) {
        return [1, "...", totalPages - 2, totalPages - 1, totalPages];
      } else {
        const pagesValues =
          pageDifference === 1
            ? []
            : currentPage + 2 === totalPages
              ? [totalPages]
              : ["...", totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, ...pagesValues];
      }
    } else {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  }, [currentPage]);

  return (
    <div className="my-4 flex flex-col items-center justify-center space-y-3">
      <div className="text-sm text-gray-600 dark:text-gray-400">
        <strong>{totalPages}</strong> kayıttan{" "}
        <strong>{currentPage - 1 + 1}</strong> ile{" "}
        <strong>{currentPage * pageSize}</strong> kayıt gösteriliyor.
      </div>
      <div>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(1)}
          className={clsx(
            "ml-2 min-h-9 min-w-9 rounded-full border border-gray-800 px-3 transition-colors disabled:opacity-50 dark:border-gray-400 dark:text-gray-400",
            {
              "hover:bg-gray-800 hover:text-white dark:hover:bg-gray-400 dark:hover:text-gray-950":
                currentPage !== 1
            }
          )}
        >
          İlk
        </button>
        {starterPages.map(page => (
          <button
            key={page}
            disabled={page === "..."}
            onClick={() => setCurrentPage(page)}
            className={clsx(
              "ml-2 min-h-9 min-w-9 rounded-full border border-gray-800 px-3 transition-colors disabled:opacity-50 dark:border-gray-400 dark:text-gray-400",
              {
                "bg-gray-800 text-white dark:bg-gray-400 dark:text-gray-950":
                  page === currentPage,
                "hover:bg-gray-800 hover:text-white dark:hover:bg-gray-400 dark:hover:text-gray-950":
                  page !== "..."
              }
            )}
          >
            {page}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(totalPages)}
          className={clsx(
            "ml-2 min-h-9 min-w-9 rounded-full border border-gray-800 px-3 transition-colors disabled:opacity-50 dark:border-gray-400 dark:text-gray-400",
            {
              "hover:bg-gray-800 hover:text-white dark:hover:bg-gray-400 dark:hover:text-gray-950":
                currentPage !== totalPages
            }
          )}
        >
          Son
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;
