import {
  IconHistory,
  IconHome,
  IconMessageChatbot,
  IconScale,
  IconGavel,
  IconHelpHexagon,
  IconScript,
  IconBookmark
} from "@tabler/icons-react";

export default [
  {
    name: "Yeni konu",
    Icon: IconHome,
    path: "/new-thread",
    mainPath: "/new-thread",
    isVisible: false
  },
  {
    name: "Menü",
    isVisible: true,
    children: [
      {
        name: "Akıllı asistan",
        Icon: IconMessageChatbot,
        path: "/new-thread",
        mainPath: "/new-thread",
        isVisible: true
      },
      {
        name: "İçtihat arama",
        Icon: IconGavel,
        path: "/search-jurisprudence",
        mainPath: "/search-jurisprudence",
        isVisible: true
      },
      {
        name: "Mevzuat arama",
        Icon: IconScale,
        path: "/search-legislation",
        mainPath: "/search-legislation",
        isVisible: true
      },
      {
        name: "Geçmiş",
        Icon: IconHistory,
        path: "/history",
        mainPath: "/history",
        isVisible: true
      },
      {
        name: "Kaydedilenler",
        Icon: IconBookmark,
        path: "/bookmarks",
        mainPath: "/bookmarks",
        isVisible: true
      },
      {
        name: "İpuçları ve S.S.S.",
        Icon: IconHelpHexagon,
        path: "/help",
        mainPath: "/help",
        isVisible: true
      },
      {
        name: "Doküman oluşturma",
        Icon: IconScript,
        path: "/create-document",
        mainPath: "/create-document",
        isVisible: true
      }
    ]
  }
];
