import React from "react";

const UnderConstruction = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center font-display text-2xl font-semibold text-gray-800 dark:text-white">
      Bu hizmet henüz yapım aşamasında
    </div>
  );
};

export default UnderConstruction;
