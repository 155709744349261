import { getQueryParams } from "../../utils/service";

export const searchApi = api => ({
  searchMevzuat(params) {
    const queryParams = getQueryParams(params);
    return api.get(`/search/mevzuat?${queryParams}`);
  },
  searchIctihat(params) {
    const queryParams = getQueryParams(params);
    return api.get(`/search/ictihat?${queryParams}`);
  }
});
