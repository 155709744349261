export const removeAllStylesFromText = htmlText => {
  htmlText = htmlText.replaceAll(/<style[\s\S]*?<\/style>/gi, "");
  htmlText = htmlText.replaceAll(/style=["'][^"']*["']/gi, "");
  htmlText = htmlText.replaceAll(/&nbsp;/g, "");
  return htmlText;
};

export const convertToHTML = text => {
  const lines = text.trim().split("\n");
  let htmlOutput = "";
  let inTable = false;

  lines.forEach(line => {
    line = line.trim();

    if (line === "") {
      htmlOutput += "<br>";
      return;
    }

    if (/^[A-ZÇŞĞÜÖİ\s]+$/.test(line)) {
      htmlOutput += `<h2>${line}</h2>`;
      return;
    }

    if (line.includes(":")) {
      const [key, value] = line.split(":").map(s => s.trim());

      if (!inTable) {
        htmlOutput += "<table>";
        inTable = true;
      }
      htmlOutput += `<tr><th>${key}</th><td>${value}</td></tr>`;
      return;
    }

    htmlOutput += `<p>${line}</p>`;
  });

  if (inTable) {
    htmlOutput += "</table>";
  }

  return htmlOutput;
};
