import React from "react";

const Terms = () => {
  return (
    <div className="flex flex-col py-10">
      <h1 className="mb-4 font-display text-2xl font-medium text-gray-950">
        Lexgine Kullanıcı Şartları
      </h1>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        1. Hizmetin Tanımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine ("Uygulama"), kullanıcılara hukuki araştırma ve hukuki sorulara
        yanıt alma imkanı sunan bir yapay zeka destekli platformdur. Uygulama,
        herhangi bir hukuki tavsiye veya temsil sağlamamaktadır.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        2. Sorumluluk Reddi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.1. Lexgine tarafından sağlanan tüm bilgiler sadece bilgilendirme
        amaçlıdır ve hiçbir şekilde hukuki tavsiye yerine geçmez.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.2. Kullanıcılar, Uygulama tarafından sağlanan bilgilerin doğruluğunu,
        eksiksizliğini veya güncelliğini bağımsız olarak doğrulamakla
        yükümlüdür.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.3. Lexgine, sağlanan bilgilerin kullanımından kaynaklanan herhangi bir
        zarar veya kayıptan sorumlu tutulamaz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        3. Kullanım Koşulları
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.1. Kullanıcılar, Uygulamayı yalnızca yasal amaçlar için kullanmayı
        kabul eder.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.2. Uygulamanın kötüye kullanımı, hesabın derhal sonlandırılmasına
        neden olabilir.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.3. Kullanıcılar, Uygulama'ya erişim bilgilerini gizli tutmakla
        yükümlüdür.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        4. Fikri Mülkiyet
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.1. Lexgine ve ilgili tüm içerik, fikri mülkiyet hakları kapsamında
        korunmaktadır.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.2. Kullanıcılar, Uygulama'nın içeriğini kopyalayamaz, dağıtamaz veya
        ticari amaçlarla kullanamaz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        5. Gizlilik
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        5.1. Lexgine, kullanıcı verilerini gizlilik politikası doğrultusunda
        işler ve korur.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        5.2. Kullanıcılar, Uygulama'ya girdikleri bilgilerin gizliliğinden
        kendileri sorumludur.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        6. Değişiklikler ve Fesih
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        6.1. Lexgine, bu şartları herhangi bir zamanda değiştirme hakkını saklı
        tutar.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        6.2. Lexgine, herhangi bir sebep göstermeksizin kullanıcı hesaplarını
        askıya alma veya sonlandırma hakkına sahiptir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        7. Garanti Reddi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine, "OLDUĞU GİBİ" ve "MEVCUT OLDUĞU KADARIYLA" sunulmaktadır.
        Uygulama, açık veya zımni hiçbir garanti vermemektedir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        8. Sorumluluğun Sınırlandırılması
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine, hiçbir durumda, Uygulama'nın kullanımından kaynaklanan
        doğrudan, dolaylı, arızi, özel veya sonuç olarak ortaya çıkan
        zararlardan sorumlu tutulamaz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        9. Uygulanacak Hukuk ve Yargı Yetkisi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu şartlar, Türkiye yasalarına tabi olacak ve bu yasalara göre
        yorumlanacaktır. Herhangi bir anlaşmazlık durumunda, Türkiye mahkemeleri
        münhasır yargı yetkisine sahip olacaktır.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        10. Kabul
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine'i kullanarak, bu şartları okuduğunuzu, anladığınızı ve kabul
        ettiğinizi beyan edersiniz.
      </p>

      <p className="text-base leading-7 text-gray-700">
        Son güncelleme tarihi: 1 Ekim 2024
      </p>
    </div>
  );
};

export default Terms;
