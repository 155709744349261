import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";

const PolicyLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen justify-center bg-white p-10 md:px-5 md:py-10">
      <div className="w-2/5 md:w-full">
        <div className="flex items-center">
          <div
            onClick={() => navigate(-1)}
            className={
              "flex w-96 cursor-pointer items-center gap-1 font-medium text-gray-700"
            }
          >
            <IconChevronLeft size={16} />
            Geri
          </div>
          <img
            src="/lexgine-logo.svg"
            width={42}
            height={42}
            className="mx-auto"
            alt="Lexgine"
          />
          <div className={"w-96"}></div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default PolicyLayout;
