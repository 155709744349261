const getParams = s3Url => {
  const params = {};
  if (s3Url.slice(-4) === "html") {
    params.s3_url_html = s3Url;
  } else {
    params.s3_url_json = s3Url;
  }

  return params;
};

export const docApi = api => ({
  ictihatSummarize(s3Url) {
    return api.post("/summarize/ictihat", getParams(s3Url));
  },
  mevzuatSummarize(s3Url) {
    return api.post("/summarize/mevzuat", getParams(s3Url));
  }
});
