import React from "react";
import { useTranslation } from "react-i18next";

const FormErrorMessage = ({ error, label }) => {
  const { t } = useTranslation();
  return (
    <>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {t(`_formErrorMessages.${error.type}`, { label })}
        </p>
      )}
    </>
  );
};

export default FormErrorMessage;
