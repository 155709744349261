import axios from "axios";

export const API_URL = "https://api.lexgine.com";

const apiConfig = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

apiConfig.interceptors.request.use(async config => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : "";
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export { apiConfig };
