import React, { useEffect, useState } from "react";
import { Button, Input, MaskInput, Pagination, Select } from "../../components";
import { useForm } from "react-hook-form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import { convertDateFormat, formatDate } from "../../utils/date";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const types = [
  {
    label: "Kanunlar",
    value: "kanunlar"
  },
  {
    label: "Cumhurbaşkanı Kararları",
    value: "cb-kararlar"
  },
  {
    label: "Tüzükler",
    value: "tuzuk"
  },
  {
    label: "Cumhurbaşkanlığı Kararnameleri",
    value: "cb-kararnameler"
  },
  {
    label: "Cumhurbaşkanlığı Genelgeleri",
    value: "cb-genelgeler"
  },
  {
    label: "Kurum, Kuruluş ve Üniversite Yönetmelikleri",
    value: "Kurum, Kuruluş ve Üniversite Yönetmelikleri"
  },
  {
    label: "Cumhurbaşkanlığı / Bakanlar Kurulu Yönetmelikleri",
    value: "Cumhurbaşkanlığı / Bakanlar Kurulu Yönetmelikleri"
  },
  {
    label: "Tebliğler",
    value: "teblig"
  }
];

const SearchLegislation = () => {
  const [showDetailSearch, setShowDetailSearch] = useState(true);
  const [results, setResults] = useState([]);
  const { isMobile } = useScreenDetector();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({});
  const [firstOpen, setFirstOpen] = useState(true);
  const [paginationData, setPaginationData] = useState({
    currentPage: page,
    has_next: true,
    has_previous: false,
    page_size: 10,
    total_pages: 10
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm();

  const onReset = () => {
    reset({
      query: "",
      mevzuat_tur: "",
      mevzuat_no: "",
      rg_sayisi: "",
      kabul_tarih_start: "",
      kabul_tarih_end: "",
      rg_tarih_start: "",
      rg_tarih_end: ""
    });
  };

  const onSubmit = async params => {
    const prepareParams = {
      ...params,
      mevzuat_tur: params.mevzuat_tur?.value,
      karar_tarih_start: convertDateFormat(params.karar_tarih_start),
      karar_tarih_end: convertDateFormat(params.karar_tarih_end),
      rg_tarih_start: convertDateFormat(params.rg_tarih_start),
      rg_tarih_end: convertDateFormat(params.rg_tarih_end)
    };

    let isValueExistInDetailSearch = false;

    for (const [key, value] of Object.entries(prepareParams)) {
      if (key !== "query" && key !== "mevzuat_tur" && value) {
        isValueExistInDetailSearch = true;
        break;
      }
    }

    if (!isValueExistInDetailSearch) {
      setShowDetailSearch(false);
    }

    const { data } = await api.search.searchMevzuat(prepareParams);
    setResults(data.results);
    setPaginationData(data.pagination);
    setParams(prepareParams);
    setFirstOpen(false);
  };

  useEffect(() => {
    if (!firstOpen) {
      const init = async () => {
        const { data } = await api.search.searchMevzuat({
          page,
          ...params
        });
        setResults(data.results);
        setPaginationData(data.pagination);
      };

      init();
    }
  }, [page]);

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4  pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        Mevzuat Arama
      </h1>
      <section className={"flex min-w-full flex-col "}>
        <div className={"flex gap-4"}>
          <Input
            name="query"
            error={errors.query}
            rules={{}}
            register={register}
            className="mb-4"
            placeholder="Aranacak kelimeyi girin"
          />
        </div>
        <div className={"flex gap-4"}>
          <Select
            options={types}
            name="mevzuat_tur"
            error={errors.mevzuat_tur}
            rules={{}}
            control={control}
            placeholder="Mevzuat Türü"
          />
        </div>
        {showDetailSearch && (
          <>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <MaskInput
                mask="999999"
                maskPlaceholder="123456"
                name="mevzuat_no"
                // @ts-ignore
                error={errors.mevzuat_no}
                register={register}
                placeholder="Mevzuat Numarası"
              />
              <MaskInput
                mask="999999"
                maskPlaceholder="123456"
                name="rg_sayisi"
                // @ts-ignore
                error={errors.rg_sayisi}
                register={register}
                placeholder="Resmi Gazete Sayısı"
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="kabul_tarih_start"
                // @ts-ignore
                error={errors.kabul_tarih_start}
                register={register}
                placeholder="Kabul Tarihi (Başlangıç)"
              />
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="kabul_tarih_end"
                // @ts-ignore
                error={errors.kabul_tarih_end}
                register={register}
                placeholder="Kabul Tarihi (Bitiş)"
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="rg_tarih_start"
                // @ts-ignore
                error={errors.rg_tarih_start}
                register={register}
                placeholder="Resmi Gazete Tarihi (Başlangıç)"
              />
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="rg_tarih_end"
                // @ts-ignore
                error={errors.rg_tarih_end}
                register={register}
                placeholder="Resmi Gazete Tarihi (Bitiş)"
              />
            </div>
          </>
        )}
        <div className={"flex items-center justify-end gap-4 md:flex-col"}>
          <button
            className="flex items-center gap-1 text-sm font-medium text-primary-600 transition-colors duration-200 ease-in-out hover:text-primary-800"
            onClick={() => setShowDetailSearch(!showDetailSearch)}
          >
            Detaylı aramayı {showDetailSearch ? "kapat" : "göster"}
            {!showDetailSearch ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </button>
          <div className={"flex justify-end gap-4 md:w-full"}>
            <Button
              onClick={() => onReset()}
              isFullWidth={isMobile}
              variant="secondary"
            >
              Sıfırla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth={isMobile}
            >
              Ara
            </Button>
          </div>
        </div>
      </section>
      <section className="mt-10 flex flex-col gap-4">
        {results.length > 0 && (
          <div className="text-sm text-gray-700 dark:text-gray-500">
            Sonuçlar: <strong>{results.length}</strong> sonuç gösteriliyor
          </div>
        )}
        {results.map((result, index) => (
          <div
            key={index}
            onClick={() =>
              navigate(
                `/doc/mevzuat?s3Url=${result.s3_url_json || result.s3_url_html}`
              )
            }
            className={
              "flex cursor-pointer items-center gap-4 rounded bg-gray-25 p-4 transition duration-300 ease-in-out hover:shadow dark:bg-gray-950 dark:hover:shadow-gray-950"
            }
          >
            <div
              className={
                "flex h-full min-w-20 items-center border-r font-medium text-gray-800 md:min-w-16 dark:border-gray-800 dark:text-white"
              }
            >
              {result.mevzuatNo}
            </div>
            <div className={"flex flex-col gap-2"}>
              <div className="text-sm font-medium text-gray-800 dark:text-gray-200">
                {result.mevAdi}
              </div>
              <div className="flex gap-3 text-xs text-gray-700 md:flex-col md:gap-1 dark:text-gray-500">
                <div className="flex gap-1">
                  <span>Tür:</span>
                  <strong className="font-medium">
                    {result.mevzuatTurEnumString}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Tertip:</span>
                  <strong className="font-medium">
                    {result.mevzuatTertip}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Resmi Gazete Tarihi:</span>
                  <strong className="font-medium">
                    {formatDate(result.resmiGazeteTarihi)}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Resmi Gazete Sayısı:</span>
                  <strong className="font-medium">
                    {result.resmiGazeteSayisi}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Kabul Tarihi:</span>
                  <strong className="font-medium">
                    {formatDate(result.kabulTarih)}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        ))}
        {results.length > 0 && (
          <Pagination
            totalPages={paginationData.total_pages}
            currentPage={page}
            pageSize={paginationData.page_size}
            setCurrentPage={pageValue => setPage(pageValue)}
          />
        )}
        {!firstOpen && results.length === 0 && (
          <div className="mt-4 flex flex-col items-center justify-center gap-1 text-gray-800 dark:text-white">
            <div className="text-lg font-semibold">Sonuç bulunamadı.</div>
            <div className="text-base text-gray-600 dark:text-gray-200">
              Arama yaptığınız kriterlere göre herhangi bir sonuç bulunamadı.
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SearchLegislation;
