export const streamFetch = async ({ url, token, body }, onChunk) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });

  const reader = response.body.getReader();
  let decoder = new TextDecoder();
  let buffer = "";
  let done = false;

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    done = readerDone;
    buffer += decoder.decode(value, { stream: !done });
    const parts = buffer.split("\n");
    buffer = parts.pop();
    parts.forEach(part => {
      onChunk(part);
    });
  }
};

export const getQueryParams = paramsObject => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(paramsObject)) {
    if (value) {
      params.append(key, value);
    }
  }

  return params.toString();
};
