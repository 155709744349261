export const ICTIHAT_UNITS = [
  {
    label: "İçtihatları Birleştirme Kurulu",
    value: "i̇ctihatlari-birlestirme-kurulu",
    institution: "danistay"
  },
  {
    label: "İdare Dava Daireleri Kurulu",
    value: "i̇dare-dava-daireleri-kurulu",
    institution: "danistay"
  },
  {
    label: "Vergi Dava Daireleri Kurulu",
    value: "vergi-dava-daireleri-kurulu",
    institution: "danistay"
  },
  {
    label: "İdari İşler Kurulu",
    value: "i̇dari-i̇sler-kurulu",
    institution: "danistay"
  },
  {
    label: "Başkanlar Kurulu",
    value: "baskanlar-kurulu",
    institution: "danistay"
  },
  {
    label: "1. Daire",
    value: "1-daire",
    institution: "danistay"
  },
  {
    label: "2. Daire",
    value: "2-daire",
    institution: "danistay"
  },
  {
    label: "3. Daire",
    value: "3-daire",
    institution: "danistay"
  },
  {
    label: "4. Daire",
    value: "4-daire",
    institution: "danistay"
  },
  {
    label: "5. Daire",
    value: "5-daire",
    institution: "danistay"
  },
  {
    label: "6. Daire",
    value: "6-daire",
    institution: "danistay"
  },
  {
    label: "7. Daire",
    value: "7-daire",
    institution: "danistay"
  },
  {
    label: "8. Daire",
    value: "8-daire",
    institution: "danistay"
  },
  {
    label: "9. Daire",
    value: "9-daire",
    institution: "danistay"
  },
  {
    label: "10. Daire",
    value: "10-daire",
    institution: "danistay"
  },
  {
    label: "11. Daire",
    value: "11-daire",
    institution: "danistay"
  },
  {
    label: "12. Daire",
    value: "12-daire",
    institution: "danistay"
  },
  {
    label: "13. Daire",
    value: "13-daire",
    institution: "danistay"
  },
  {
    label: "14. Daire",
    value: "14-daire",
    institution: "danistay"
  },
  {
    label: "15. Daire",
    value: "15-daire",
    institution: "danistay"
  },
  {
    label: "16. Daire",
    value: "16-daire",
    institution: "danistay"
  },
  {
    label: "17. Daire",
    value: "17-daire",
    institution: "danistay"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi",
    value: "askeri-yuksek-i̇dare-mahkemesi",
    institution: "ayim"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi Daireler Kurulu",
    value: "askeri-yuksek-i̇dare-mahkemesi-daireler-kurulu",
    institution: "ayim"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi Başsavcılığı",
    value: "askeri-yuksek-i̇dare-mahkemesi-bassavciligi",
    institution: "ayim"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi 1. Daire",
    value: "askeri-yuksek-i̇dare-mahkemesi-1-daire",
    institution: "ayim"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi 2. Daire",
    value: "askeri-yuksek-i̇dare-mahkemesi-2-daire",
    institution: "ayim"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi 3. Daire",
    value: "askeri-yuksek-i̇dare-mahkemesi-3-daire",
    institution: "ayim"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 23. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-23-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 8. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-8-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 17. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-17-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Asliye Ticaret Mahkemesi",
    value: "sakarya-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 17. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-17-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 1. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 35. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-35-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 9. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-9-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 46. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-46-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 8. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-8-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 22. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-22-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 8. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-8-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 1. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "ankara-1-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 18. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-18-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Kayseri Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "kayseri-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 31. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-31-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 4. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "ankara-4-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 12. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-12-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 8. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-8-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 38. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-38-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir 7. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-7-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 8. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-8-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 9. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-9-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya 2. Asliye Ticaret Mahkemesi",
    value: "konya-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 8. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-8-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 11. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-11-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 25. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-25-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 14. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-14-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya 1. Asliye Ticaret Mahkemesi",
    value: "antalya-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Adana 1. Asliye Ticaret Mahkemesi",
    value: "adana-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İzmir 5. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-5-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 2. Asliye Ticaret Mahkemesi",
    value: "ankara-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 3. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 5. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-5-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 16. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-16-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 4. Asliye Ticaret Mahkemesi",
    value: "ankara-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 9. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-9-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 12. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-12-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 2. Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-anadolu-2-fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 2. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 9. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-9-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 15. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-15-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 6. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-6-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Samsun Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "samsun-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 3. Asliye Ticaret Mahkemesi",
    value: "ankara-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 8. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-8-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 55. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-55-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 27. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-27-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-anadolu-fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 24. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-24-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir 4. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 6. Asliye Ticaret Mahkemesi",
    value: "ankara-6-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep 3. Asliye Ticaret Mahkemesi",
    value: "gaziantep-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya 4. Asliye Ticaret Mahkemesi",
    value: "antalya-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa 1. Asliye Ticaret Mahkemesi",
    value: "bursa-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 40. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-40-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Samsun Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "samsun-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 10. Asliye Ticaret Mahkemesi",
    value: "ankara-10-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İzmir 6. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-6-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 13. Asliye Ticaret Mahkemesi",
    value: "ankara-13-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 28. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-28-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Erzurum Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "erzurum-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 12. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-12-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 3. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "ankara-3-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana 3. Asliye Ticaret Mahkemesi",
    value: "adana-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Kayseri 2. Asliye Ticaret Mahkemesi",
    value: "kayseri-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 45. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-45-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Kayseri Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "kayseri-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Trabzon Asliye Ticaret Mahkemesi",
    value: "trabzon-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 12. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-12-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 5. Asliye Ticaret Mahkemesi",
    value: "ankara-5-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 10. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-10-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 1. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 15. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-15-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya 4. Asliye Ticaret Mahkemesi",
    value: "konya-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya 3. Asliye Ticaret Mahkemesi",
    value: "antalya-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 49. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-49-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Tekirdağ Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "tekirdag-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir 2. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İzmir Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇zmir-fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 2. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "bakirkoy-2-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Kayseri Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "kayseri-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 3. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Karşıyaka Asliye Ticaret Mahkemesi",
    value: "karsiyaka-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 4. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 6. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-6-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 17. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-17-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya 1. Asliye Ticaret Mahkemesi",
    value: "konya-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 7. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-7-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Samsun Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "samsun-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 11. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-11-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 12. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-12-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 15. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-15-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 14. Asliye Ticaret Mahkemesi",
    value: "ankara-14-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 4. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Kayseri Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "kayseri-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 20. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-20-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 9. Asliye Ticaret Mahkemesi",
    value: "ankara-9-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Samsun Asliye Ticaret Mahkemesi",
    value: "samsun-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Trabzon Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "trabzon-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir 1. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 5. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-5-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 57. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-57-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 13. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-13-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 2.Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-2fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 41. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-41-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 37. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-37-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 27. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-27-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 5. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "ankara-5-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 17. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-17-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 24. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-24-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Erzurum Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "erzurum-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 4. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-4-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 17. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-17-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 51. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-51-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Batı Asliye Ticaret Mahkemesi",
    value: "ankara-bati-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 12. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-12-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 7. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-7-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 23. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-23-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya 3. Asliye Ticaret Mahkemesi",
    value: "konya-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 31. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-31-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 4.Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-4fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa 2. Asliye Ticaret Mahkemesi",
    value: "bursa-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Istanbul Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "istanbul-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 43. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-43-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 16. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-16-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 25. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-25-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 5. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-5-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 21. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-21-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 1.Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-1fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 53. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-53-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 10. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-10-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bursa 3. Asliye Ticaret Mahkemesi",
    value: "bursa-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 26. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-26-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Trabzon Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "trabzon-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 20. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-20-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 37. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-37-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 20. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-20-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Kayseri Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "kayseri-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 35. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-35-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya 2. Asliye Ticaret Mahkemesi",
    value: "antalya-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Denizli Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "denizli-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 2. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 30. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-30-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Erzurum Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "erzurum-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Bursa Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "bursa-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 34. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-34-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 18. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-18-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 11. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-11-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 8. Asliye Ticaret Mahkemesi",
    value: "ankara-8-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 3.Fikrî ve Sınaî Haklar Hukuk Mahkemesi",
    value: "i̇stanbul-3fikrî-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 15. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-15-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 36. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-36-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 19. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-19-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Trabzon Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "trabzon-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 32. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-32-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 22. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-22-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 1. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep 2. Asliye Ticaret Mahkemesi",
    value: "gaziantep-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 9. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-9-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 48. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-48-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 26. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-26-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 22. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-22-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 1. Asliye Ticaret Mahkemesi",
    value: "ankara-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 21. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-21-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 6. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-6-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 33. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-33-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "adana-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 54. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-54-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 19. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-19-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Samsun Bölge Adliye Mahkemesi 2. Hukuk Dairesi",
    value: "samsun-bolge-adliye-mahkemesi-2-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir 3. Asliye Ticaret Mahkemesi",
    value: "i̇zmir-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 12. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-12-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Konya Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "konya-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Adana 2. Asliye Ticaret Mahkemesi",
    value: "adana-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 3. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-3-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Samsun Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "samsun-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 7. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-7-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 10. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-10-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 3. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-3-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 9. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-9-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 21. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-21-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 36. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-36-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 5. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-5-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 10. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-10-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 47. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-47-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Asliye Ticaret Mahkemesi",
    value: "diyarbakir-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep 1. Asliye Ticaret Mahkemesi",
    value: "gaziantep-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 7. Asliye Ticaret Mahkemesi",
    value: "bakirkoy-7-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 9. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-9-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Trabzon Bölge Adliye Mahkemesi 1. Hukuk Dairesi",
    value: "trabzon-bolge-adliye-mahkemesi-1-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 16. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-16-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 16. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-16-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 24. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-24-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 6. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-6-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 23. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-23-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 8. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-8-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Diyarbakır Bölge Adliye Mahkemesi 10. Hukuk Dairesi",
    value: "diyarbakir-bolge-adliye-mahkemesi-10-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Antalya Bölge Adliye Mahkemesi 16. Hukuk Dairesi",
    value: "antalya-bolge-adliye-mahkemesi-16-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Sakarya Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "sakarya-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Anadolu 13. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-anadolu-13-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 21. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-21-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Kayseri 1. Asliye Ticaret Mahkemesi",
    value: "kayseri-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Test 1. Asliye Ticaret Mahkemesi",
    value: "test-1-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 2. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "ankara-2-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 7. Asliye Ticaret Mahkemesi",
    value: "ankara-7-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Bakırköy 1. Fikri ve Sınaî Haklar Hukuk Mahkemesi",
    value: "bakirkoy-1-fikri-ve-sinaî-haklar-hukuk-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara 11. Asliye Ticaret Mahkemesi",
    value: "ankara-11-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 16. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-16-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul 2. Asliye Ticaret Mahkemesi",
    value: "i̇stanbul-2-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 15. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-15-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara Bölge Adliye Mahkemesi 17. Hukuk Dairesi",
    value: "ankara-bolge-adliye-mahkemesi-17-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 29. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-29-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Ankara 12. Asliye Ticaret Mahkemesi",
    value: "ankara-12-asliye-ticaret-mahkemesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 4. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-4-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Gaziantep Bölge Adliye Mahkemesi 13. Hukuk Dairesi",
    value: "gaziantep-bolge-adliye-mahkemesi-13-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 14. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-14-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İzmir Bölge Adliye Mahkemesi 20. Hukuk Dairesi",
    value: "i̇zmir-bolge-adliye-mahkemesi-20-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "İstanbul Bölge Adliye Mahkemesi 44. Hukuk Dairesi",
    value: "i̇stanbul-bolge-adliye-mahkemesi-44-hukuk-dairesi",
    institution: "uyap"
  },
  {
    label: "Hukuk Genel Kurulu",
    value: "hukuk-genel-kurulu",
    institution: "yargitay"
  },
  {
    label: "Ceza Genel Kurulu",
    value: "ceza-genel-kurulu",
    institution: "yargitay"
  },
  {
    label: "Ceza Daireleri Başkanlar Kurulu",
    value: "ceza-daireleri-baskanlar-kurulu",
    institution: "yargitay"
  },
  {
    label: "Hukuk Daireleri Başkanlar Kurulu",
    value: "hukuk-daireleri-baskanlar-kurulu",
    institution: "yargitay"
  },
  {
    label: "Büyük Genel Kurulu",
    value: "buyuk-genel-kurulu",
    institution: "yargitay"
  },
  {
    label: "1. Hukuk Dairesi",
    value: "1-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "2. Hukuk Dairesi",
    value: "2-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "3. Hukuk Dairesi",
    value: "3-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "4. Hukuk Dairesi",
    value: "4-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "5. Hukuk Dairesi",
    value: "5-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "6. Hukuk Dairesi",
    value: "6-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "7. Hukuk Dairesi",
    value: "7-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "8. Hukuk Dairesi",
    value: "8-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "9. Hukuk Dairesi",
    value: "9-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "10. Hukuk Dairesi",
    value: "10-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "11. Hukuk Dairesi",
    value: "11-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "12. Hukuk Dairesi",
    value: "12-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "13. Hukuk Dairesi",
    value: "13-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "14. Hukuk Dairesi",
    value: "14-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "15. Hukuk Dairesi",
    value: "15-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "16. Hukuk Dairesi",
    value: "16-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "17. Hukuk Dairesi",
    value: "17-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "18. Hukuk Dairesi",
    value: "18-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "19. Hukuk Dairesi",
    value: "19-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "20. Hukuk Dairesi",
    value: "20-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "21. Hukuk Dairesi",
    value: "21-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "22. Hukuk Dairesi",
    value: "22-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "23. Hukuk Dairesi",
    value: "23-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "1. Ceza Dairesi",
    value: "1-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "2. Ceza Dairesi",
    value: "2-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "3. Ceza Dairesi",
    value: "3-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "4. Ceza Dairesi",
    value: "4-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "5. Ceza Dairesi",
    value: "5-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "6. Ceza Dairesi",
    value: "6-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "7. Ceza Dairesi",
    value: "7-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "8. Ceza Dairesi",
    value: "8-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "9. Ceza Dairesi",
    value: "9-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "10. Ceza Dairesi",
    value: "10-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "11. Ceza Dairesi",
    value: "11-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "12. Ceza Dairesi",
    value: "12-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "13. Ceza Dairesi",
    value: "13-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "14. Ceza Dairesi",
    value: "14-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "15. Ceza Dairesi",
    value: "15-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "16. Ceza Dairesi",
    value: "16-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "17. Ceza Dairesi",
    value: "17-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "18. Ceza Dairesi",
    value: "18-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "19. Ceza Dairesi",
    value: "19-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "20. Ceza Dairesi",
    value: "20-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "21. Ceza Dairesi",
    value: "21-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "22. Ceza Dairesi",
    value: "22-ceza-dairesi",
    institution: "yargitay"
  },
  {
    label: "23. Ceza Dairesi",
    value: "23-ceza-dairesi",
    institution: "yargitay"
  }
];
