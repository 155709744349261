import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { ChatInput } from "../index";
import { isDarkTheme } from "../../utils/theme";

Modal.setAppElement("#root");

const ChatModalComponent = ({ isOpen, closeModal }) => {
  const inputRef = useRef(null);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      border: "none",
      minWidth: 672,
      backgroundColor: "transparent"
    },
    overlay: {
      backgroundColor: isDarkTheme()
        ? "rgba(0, 0, 0, 0.8)"
        : "rgba(0, 0, 0, 0.3)"
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      });
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <ChatInput ref={inputRef} />
    </Modal>
  );
};

export default ChatModalComponent;
