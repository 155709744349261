import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "context/auth.context";
import { Button, Sidebar } from "../components";
import menu from "../constants/menu";
import {
  IconChevronLeft,
  IconLayoutSidebar,
  IconPencil,
  IconX
} from "@tabler/icons-react";
import { useScreenDetector } from "../hooks/use-screen-detector";
import { useTranslation } from "react-i18next";

const ChatLayout = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { isMobile, width } = useScreenDetector();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [isSidebarSmall, setIsSidebarSmall] = useState(false);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const canGoBack = location.key !== "default" && location.pathname !== "/";
  const navigate = useNavigate();
  const { t } = useTranslation();

  let page = {
    isVisible: true,
    name: "Anasayfa",
    path: "/",
    mainPath: "/"
  };

  menu.map(item => {
    if (item.children) {
      const getPage = item.children.find(
        child => child.path === location.pathname
      );
      if (getPage) {
        page = getPage;
      }
      if (location.pathname.substring(0, 6) === "/chat/") {
        page.path = "/chat";
        page.mainPath = "/chat";
      }
    }
  });

  useEffect(() => {
    if (isMobile) {
      setIsSidebarSmall(false);
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isSidebarOpen === false) {
      localStorage.setItem("isSidebarHidden", "true");
    } else {
      localStorage.removeItem("isSidebarHidden");
    }
  }, [isSidebarOpen]);

  if (!user) {
    return <Navigate to="/auth/welcome" replace={true} />;
  }

  useEffect(() => {
    setIsSidebarHidden(width < 1218);
    if (width < 1218) {
      setIsSidebarSmall(true);
    }
  }, [width]);

  return (
    <div className="flex h-screen bg-gray-25 dark:bg-gray-950">
      <div
        className={`z-20 flex border-gray-900 bg-gray-25 px-4 pb-5 pt-5 transition duration-500 md:fixed md:bottom-0 md:top-0 dark:bg-gray-950 ${isSidebarOpen ? "w-1/6 md:w-5/6" : "md:hidden md:w-0"} ${isSidebarSmall ? "w-20" : "w-1/6"}`}
      >
        <Sidebar activePage={page} isSidebarSmall={isSidebarSmall} />
        <div
          onClick={() => setIsSidebarOpen(prevState => !prevState)}
          className="bottom-0 right-0 top-0 hidden w-1/6 bg-gray-400 py-6 pl-4 text-white opacity-80 md:fixed md:block dark:bg-gray-800"
        >
          <IconX size={24} />
        </div>
      </div>
      <div
        className={`no-scrollbar my-3 mr-3 flex max-h-screen w-5/6 flex-col overflow-y-scroll rounded-xl bg-white shadow transition duration-500 md:ml-3 md:w-full dark:bg-gray-900 ${isSidebarSmall ? "w-full flex-1" : ""}`}
      >
        <div
          className={
            "sticky top-0 z-10 mb-6 flex items-center justify-between border-b border-gray-100 bg-white py-1 dark:border-gray-800 dark:bg-gray-900"
          }
        >
          <div className="flex w-[84px] items-center gap-1 pl-4">
            <button
              title={isSidebarSmall ? "Sidebar'ı Aç" : "Sidebar'ı Kapat"}
              className="flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full text-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 hover:dark:bg-gray-800 hover:dark:text-gray-300"
              onClick={() => {
                if (isMobile) {
                  setIsSidebarSmall(false);
                  setIsSidebarOpen(prevState => !prevState);
                } else {
                  if (isSidebarHidden) return;
                  setIsSidebarSmall(prevState => !prevState);
                }
              }}
            >
              <IconLayoutSidebar size={24} />
            </button>
            {canGoBack && (
              <button
                title="Geri"
                disabled={!canGoBack}
                className="flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full text-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 hover:dark:bg-gray-800 hover:dark:text-gray-300"
                onClick={() => navigate(-1)}
              >
                <IconChevronLeft size={20} />
              </button>
            )}
          </div>
          {(location.pathname.substring(0, 6) === "/chat/" ||
            location.pathname.substring(0, 5) === "/doc/") && (
            <Button
              variant="secondary"
              onClick={() => navigate("/")}
              className={`flex h-9 gap-1 rounded-full hover:bg-primary-600 hover:text-white ${isMobile ? "max-w-36" : "max-w-52"}`}
            >
              <span className={"text-base font-medium"}>
                {t("_sidebar.newChat")}
              </span>
              <IconPencil size={16} stroke={3} />
            </Button>
          )}
          <div className="w-[84px]"></div>
        </div>
        <div className="no-scrollbar flex max-h-screen w-full flex-1 flex-col px-10 pb-10 md:px-5 md:pb-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ChatLayout;
