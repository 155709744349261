import React from "react";
import { useAuth } from "../../context/auth.context";

const Profile = () => {
  const { user } = useAuth();
  return (
    <div className="flex  w-full flex-col justify-center">
      <h1
        className={
          "mb-4 border-b border-gray-200 pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        Profilim
      </h1>
      <div className="flex flex-col gap-2">
        <div className={"text-base text-gray-600 dark:text-gray-400"}>
          İsim Soyisim:
          <span className={"ml-2 font-medium"}>
            {user.given_name}

            {user.family_name}
          </span>
        </div>
        <div className={"text-base text-gray-600 dark:text-gray-400"}>
          Email:
          <span className={"ml-2 font-medium"}>{user.email}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
